.boxContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1;
}

.boxDiv {
    height: 70px;
    border: 1px solid black;
    flex-grow: 0.1;
}
