@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
html{
    scroll-behavior: smooth !important;
}
body{
    font-family: 'Inter', sans-serif !important;
    overflow-x: hidden !important;
    letter-spacing: .3px !important;
}
:root{
    --theme: #2EC46D !important;
}
h1,h2,h3,h4,h5,h6{
    color: #2EC46D !important;
}
.bg-theme{
    background-color: var(--theme) !important;
}
.mt-10vh{
    margin-top: 10vh !important;
}
.mt-15vh{
    margin-top: 15vh !important;
}
.mt-20vh{
    margin-top: 20vh !important;
}
.mt-25vh{
    margin-top: 25vh !important;
}
.mt-30vh{
    margin-top: 30vh !important;
}
.mt-35vh{
    margin-top: 35vh !important;
}
.bg-theme{
    background-color: var(--theme) !important;
}

.btn-def{
    box-shadow: none !important;
    padding: .65rem 1.45rem !important;
    border-radius: .4rem !important;
}
.btn-small{
    box-shadow: none !important;
    padding: .45rem 0.45rem !important;
    border-radius: .4rem !important;
    font-size: 13px;
}
a{
    cursor: pointer;
    color: var(--theme) !important;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
  }
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  audio {
    border-radius: 90px;
    }
