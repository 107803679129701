.bodyContainer {
    /* margin: 10px;
    padding: 10px; */
    height: auto;
    padding: 10px;
    /* width: 99%; */
    border: 1px dashed grey;
    border-radius: 8px;
    margin-left: 250px;
    margin-right: 250px;
    margin-top: 20px;
    /* height: "150px";
    padding: "10px";
    width: "100%"; */
    /* border: "1px dashed grey";
    border-radius: "8px";
    font-size: "13px"; */
    /* margin: "10px"; */
}
.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 10px;
    margin-left: 250px;
    margin-right: 250px;
}