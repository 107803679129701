.login-container {
    position: relative;
    max-width: 400px;
    margin: 4rem auto;
    height: 100%;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
    background: white;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
}


.login-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-weight: 900;
    font-size: 1.8rem;
    color: #1da1f2;
    letter-spacing: 1px;
}

.login-form {
    text-align: left;
    margin-top: 50px;
}

.login-container label,
.login-container input,
.login-container button {
    display: block;
    width: 90%;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
}

.login-container label {
    margin-bottom: 4px;
    color: gray;
    margin: 15px 0 5px 5px;
}

.login-container input::placeholder {
    color: gray;
}

.login-container input {
    background: #ecf0f3;
    margin: 15px 0 0 0;
    padding: 10px;
    padding-left: 10px;
    height: 50px;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: inset 3px 3px 3px #cbced1, inset -3px -3px 3px white;
}

.login-container button {
    color: white;
    margin-top: 20px;
    background: #1da1f2;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
    box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
    transition: 0.5s;
}

.login-container button:hover {
    box-shadow: none;
    background-color: #1b80bf;
}


.error-message{
    font-size: 12px;
    color: red;
    margin:10px;
}
.error-message::before{
    content: "❌ ";
}
